import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginatedList } from '@models/common/paginated-list.class';
import { InternetProtocolConfiguration, Station, StationGroup, StationGroupAdd, StationGroupEdit } from '@models/station/station.class';
import { STATIONGROUPS_SET } from '@store/stationGroups/stationGroups.actions';
import { Channel, ChannelStatus, RelayChannelLog, RelayConfiguration } from '@app/models/station/relayConfiguration.class';
import { STATIONS_SET } from '../store/stations/stations.actions';

@Injectable()
export class StationService {
  private uri: string = `${environment.routes.apiEndpoint}/station`;
  constructor(private http: HttpClient, private store: Store) { }

  public getAllStations(): void {
    this.http.get<PaginatedList<Station>>(`${this.uri}/stations?PageSize=1000`).subscribe(
      (stations: PaginatedList<Station>) => {
        this.store.dispatch(STATIONS_SET({ stations: stations.items }));
      });
  }

  public getStations() {
    return this.http.get<PaginatedList<Station>>(`${this.uri}/stations?PageSize=1000`);
  }

  public async getAllStationGroups() {
    return this.http.get<PaginatedList<StationGroup>>(`${this.uri}/stationGroups?PageSize=1000`).subscribe(
      (groups: PaginatedList<StationGroup>) => {
        this.store.dispatch(STATIONGROUPS_SET({ stationGroups: groups.items }));
      }
    )
  }

  public getStationGroups() {
    return this.http.get<PaginatedList<StationGroup>>(`${this.uri}/stationGroups?PageSize=2000`);
  }

  public getAllStationsNotInInfluenceRegion(name: string | null = null) {
    let filters = '&IsConnectedToInfluenceRegion=false';
    if (name) filters += `&Name=${name}`;
    return this.http.get<PaginatedList<Station>>(`${this.uri}/stations?PageSize=1000${filters}`);
  }

  public addStationGroup(stationGroup: StationGroupAdd) {
    return this.http.post<StationGroup>(`${this.uri}/stationGroups`, stationGroup);
  }

  public updateStationGroup(stationGroup: StationGroupEdit) {
    return this.http.put<StationGroup>(`${this.uri}/stationGroups`, stationGroup);
  }

  public deleteStationGroup(stationGroupId: string) {
    return this.http.delete<StationGroup>(`${this.uri}/stationGroups/${stationGroupId}`);
  }

  public patchInternetProtocol(stationId: string, internetProtocol: InternetProtocolConfiguration) {
    return this.http.patch(`${this.uri}/stations/${stationId}/InternetProtocol`, internetProtocol);
  }

  public getRelayConfigs(stationIds: string[] = [],
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'created', sortDescending: boolean = true
  ) {
    let filters = `PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortField=${sortField}&SortDescending=${sortDescending}`;
    if (stationIds && stationIds.length) {
      filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    }
    return this.http.get<PaginatedList<RelayConfiguration>>(`${this.uri}/relayConfigurations?${filters}`);
  }

  public setRelayChannelStatus(relayId: string, channelNumber: number, status: number, reason: string = '') {
    return this.http.put(`${this.uri}/relayConfigurations/${relayId}/channel/${channelNumber}/status/`, {
      status: status,
      reason: reason
    });
  }

  public updateRelayConfiguration(relayId: string, channels: Channel[]) {
    return this.http.put(`${this.uri}/relayConfigurations/${relayId}`, { channels });
  }

  public getChannelLogs(
    stationIds: string[] = [], stationGroupIds: string[] = [],
    statuses: ChannelStatus[] = [], channelNumbers: number[] = [],
    isActive?: boolean,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'created', sortDescending: boolean = true
  ) {
    let filters = `PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortField=${sortField}&SortDescending=${sortDescending}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    if (statuses.length) filters += `&Statuses=${statuses.join('&Statuses=')}`;
    if (channelNumbers.length) filters += `&Numbers=${channelNumbers.join('&Numbers=')}`;
    if (isActive !== undefined) filters += `&IsActive=${isActive}`;

    return this.http.get<PaginatedList<RelayChannelLog>>(`${this.uri}/relayConfigurations/channelLogs?${filters}`);
  }
}
